<template>
    <header id="header" class="fixed-top border-bottom bg-main shadow-sm">
    <div class="container-fluid shadow-sm">
      <nav class="navbar navbar-expand-md navbar-dark px-0 pr-sm-3 navbar-offcanvas">
        <a id="backBtn" class="btn p-0 mr-md-4">
          <i class="fa fa-arrow-left text-light h5 mb-0"></i>
        </a>
        <span class="navbar-brand bg-transparent rounded p-0 text-center mr-0 mr-md-5">
          <span class="h6 font-weight-bold text-truncate">Chat</span>
        </span>
        <a class="btn px-0 d-block d-md-none" data-toggle="offcanvas">
          <i class="fa fa-ellipsis-h text-light"></i>
        </a>
        <div class="navbar-collapse row p-3 pb-5 p-md-0 mt-2 mt-md-0 offcanvas-collapse" id="collapsibleNavbar">
          <ul class="navbar-nav w-100 pl-3 pl-md-0">
            <li class="nav-item mt-1 mb-n1 active">
              <a href="./jual-chat.html" class="nav-link" title="Chat Aktif">Chat Aktif</a>
            </li>
            <li class="nav-item mt-1 mb-n1 active">
              <a href="./jual-chat.html" class="nav-link" title="Riwayat">Riwayat</a>
            </li>
          </ul>
        </div>
      </nav>
    </div><!-- .container -->
  </header>

  <div class="container-fluid mt-header">
    <div class="row" id="body-sidemenu">
      <!-- MAIN -->
      <div id="main-content" class="col with-fixed-sidebar">

        <div class="row d-none">
          <div class="col-12">
            <div class="alert alert-secondary bg-light alert-dismissible fade show" role="alert">
              <button type="button" class="close" data-dismiss="alert">&times;</button>
              <strong>Alert!</strong> You should <a href="javascript:void(0);" class="alert-link" title="Click to go to this message">read this message</a>.
              <button id="submitButton" class="btn btn-sm btn-light border py-0 mt-n1" type="submit" title="Action..."><i class="fa fa-user mr-1"></i>Action</button>
            </div>
          </div> <!-- .col -->
        </div> <!-- .row -->

        <div class="row mt-3 mb-5">
          <div class="col-12">
          </div><!-- .col -->
        </div><!-- .row -->
  
      </div><!-- main-content -->
    </div><!-- .row -->
  </div><!-- .container -->
</template>